<template>
  <div class="pt-24 enumerator-teams">
    <v-tour name="firstStepTour" :steps="firstSteps" :callbacks="tourCallbacks"/>
    <v-tour name="secondStepTour" :steps="secondSteps" :callbacks="tourCallbacks"/>
    <div class="txt-grey">{{ $t('components.description.holdShiftOnYourKeyboardToSelect') }}</div>
    <a-card
      v-for="(team, index) of teams"
      :key="index"
      :bordered="true"
      class="ant-card-no-padding team-card">
      <div
        class="team-container"
        :class="{ 'toggle-closed': index == 0 && teams['0'].length === 0 }">
        <div class="flex justify-space-between align-center">
          <div class="txt-20 txt-font-din-medium flex">
            <a-button
              type="link"
              class="pointer flex align-center"
              @click="toggleExpand($event)">
              <embed
                width="16px"
                src="/chevron-down-orange.svg">
            </a-button>
            <span v-if="index == 0">{{ $t('values.unassigned') }} ({{ team.length }})</span>
            <span v-else class="txt-capitalize">
              {{ $tc('values.team', 1) }} {{ toWords(index) }} ({{ team.length }})
            </span>
          </div>
          <div v-if="index > 0" class="flex align-center">
            <a-popconfirm
              :title="$t('components.notifications.areYouSureYouWantToDeleteThisTeam')"
              :ok-text="$t('values.yes')"
              :cancel-text="$t('values.no')"
              :disabled="isSupervisor"
              @confirm="deleteTeam(index)">
              <a-button
                class="mr-20"
                :class="{ 'v-step-3': parseInt(index, 0) === Object.keys(teams).length - 1 }"
                :disabled="isSupervisor"
                type="danger"
                shape="circle"
                icon="delete"/>
            </a-popconfirm>
          </div>
        </div>
        <draggable
          :disabled="isSupervisor"
          :list="teams[index]"
          group="enumerators"
          draggable=".enumerator-row"
          handle=".drag-handle"
          ghost-class="ghost"
          class="enumerator-dropzone"
          :scroll-sensitivity="200"
          :force-fallback="true"
          :data-index="index"
          @start="onDragStart"
          @end="onDragEnd">
          <div slot="header" class="team-row header">
            <div class="flex justify-center"></div>
            <div class="txt-font-din-medium">
              {{ $t('values.name') }}
            </div>
            <div class="txt-font-din-medium">
              {{ $t('values.group') }}
            </div>
            <div class="txt-font-din-medium">
              {{ $t('components.titles.heightOutcome') }}
            </div>
            <div class="txt-font-din-medium">
              {{ $t('components.titles.weightOutcome') }}
            </div>
            <div class="txt-font-din-medium">
              {{ $t('components.titles.muacOutcome') }}
            </div>
            <div class="txt-font-din-medium">
              {{ $t('values.role') }}
            </div>
          </div>
          <div
            v-for="(enumerator, enumeratorIndex) in team"
            :key="enumerator.id"
            :data-enumerator="enumerator.id"
            :data-enumerator-index="enumeratorIndex"
            :class="{ selected: teams[index][enumeratorIndex].selected }"
            class="team-row enumerator-row"
            @click="selectRow($event, index, enumeratorIndex)">
            <a-icon type="drag" :style="{ fontSize: '20px', color: '#ccc' }" :class="[isSupervisor ? undefined : 'drag-handle']"/>

            <div
              :key="enumerator.id"
              class="txt-font-din-medium pointer txt-underline"
              @click="onViewEnumerator($event, enumerator)">
              {{ enumerator.firstName }} {{ enumerator.familyName }}
            </div>
            <div>
              {{ enumerator.groupName }}
            </div>
            <div>
              <StandardizationOutcome :enumerator="enumerator" property="height"/>
            </div>
            <div>
              <StandardizationOutcome :enumerator="enumerator" property="weight"/>
            </div>
            <div>
              <StandardizationOutcome :enumerator="enumerator" property="muac"/>
            </div>
            <div v-if="index != 0">
              <a-select
                v-model="teams[index][enumeratorIndex].teamRole"
                class="w170 role-selection"
                placeholder="Select a role"
                :get-popup-container="(triggerNode) => triggerNode.parentNode"

                @change="updateValue">
                <a-select-option
                  :disabled="isSupervisor"
                  :value="undefined">
                  {{ $t('values.unassigned') }}
                </a-select-option>
                <a-select-option
                  value="team leader"
                  :disabled="teamHasRole(index, 'team leader') || isSupervisor">
                  {{ $t('components.titles.teamLeader') }}
                </a-select-option>
                <a-select-option
                  value="measurer"
                  :disabled="teamHasRole(index, 'measurer') || isSupervisor">
                  {{ $t('values.measurer') }}
                </a-select-option>
                <a-select-option
                  value="assistant measurer"
                  :disabled="teamHasRole(index, 'assistant measurer') || isSupervisor">
                  {{ $t('components.titles.assistantMeasurer') }}
                </a-select-option>
                <a-select-option
                  value="field supervisor"
                  :disabled="teamHasRole(index, 'field supervisor') || isSupervisor">
                  {{ $t('components.titles.fieldSupervisor') }}
                </a-select-option>
              </a-select>
            </div>
            <div v-else class="opacity02 txt-normal">
              {{ $t('components.titles.requiresTeam') }}
            </div>
          <EnumeratorProfile
            v-if="enumeratorViewing && enumeratorViewing.id === enumerator.id"
            :has-supervisor="hasSupervisor"
            :on-exit="onViewEnumerator"
            :on-updated="onUpdate"
            :on-enumerator-update="onEnumeratorUpdate"
            :team="index"
            :enumerator-index="enumeratorIndex"
            :enumerator="enumerator"/>
          </div>
          <div class="team-row-empty txt-grey">
            {{ $t('components.description.noEnumeratorsAssigned') }}
          </div>
        </draggable>
      </div>
    </a-card>
    <div>
      <a-button
        id="v-step-2"
        class="mb-24 w170"
        type="primary"
        size="large"
        :disabled="isSupervisor"
        @click="addTeam">
        + {{ $t('components.titles.addTeam') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import converter from 'number-to-words';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import StandardizationOutcome from '../StandardizationOutcome/standardization-outcome.vue';
import { configForPossibleBackendRequest } from '../../../../util/request';

// how long to wait before updating enumerators in ms
const debounceDelay = 2000;

// key for saving message
const messageKey = 'EnumeratorTeamsMessageKey';

export default {
  name: 'EnumeratorTeams',
  components: {
    draggable,
    StandardizationOutcome,
    EnumeratorProfile: () => import('../EnumeratorProfile/enumerator-profile')
  },
  props: {
    value: {
      type: Array,
      default: undefined
    },
    enumerators: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentStep: 0,
      teams: {},
      previousEnums: {},
      enumeratorViewing: undefined,
      clusterOverride: {},
      tourCallbacks: {
        onSkip() {
          window.localStorage.setItem('skipEnumeratorTour', true);
        }
      },
      firstSteps: [
        {
          target: '.drag-handle',
          content: this.$t('components.notifications.startDragEnumeratorTeam'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#v-step-2',
          content: this.$t('components.notifications.youNeedAddMoreTeams')
        },
        {
          target: '.v-step-3',
          content: this.$t('components.notifications.youNeedDeleteTeam'),
          params: {
            placement: 'bottom'
          }
        }
      ],
      secondSteps: [
        {
          target: '.role-selection',
          content: this.$t('components.notifications.assignRoleEnumaterator'),
          params: {
            enableScrolling: false
          }
        }
      ]
    };
  },
  computed: {
    ...mapState({
      project: state => state.survey.project,
      surveyId: state => state.survey.surveyId,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup',
      lastUpdated: state => state.standardizationGroups.lastUpdated,
      teamCount: state => (state.survey.metadata && state.survey.metadata.numberOfTeams) || 1,

      temBias: state => state.standardizationGroups.temBias,
      lastTemBiasUpdated: state => state.standardizationGroups.lastTemBiasUpdated
    })
  },
  watch: {
    surveyId: {
      handler() {
        this.fetchPageData();
      }
    },
    lastUpdated: {
      deep: true,
      handler() {
        this.computeTeams();
      }
    },
    lastTemBiasUpdated: {
      deep: true,
      handler(newVal) {
        this.computeTeams();
      }
    },
    currentStep(value) {
      const isSkipped = window.localStorage.getItem('skipEnumeratorTour');
      if (isSkipped) return;
      const enumeratorsWithTeams = this.enumerators.filter(enumerator => enumerator.teamId);
      if (enumeratorsWithTeams.length === 0) {
        if (value === 1) {
          this.$tours.secondStepTour.start();
        }
      }
    }
  },
  mounted() {
    this.fetchPageData();
    this.computeTeams();
  },
  destroyed() {
    this.sendEnumeratorTeams();
  },
  methods: {
    ...mapActions([
      'getTemBiasForWholeSurvey',
      'updateEnumeratorInGroup',
      'updateEnumeratorRanksForSurvey',
      'setEnumerators'
    ]),
    hasSupervisor(groupId) {
      return this.enumerators.findIndex(
        enumerator => enumerator &&
        enumerator.supervisor &&
        enumerator.groupId === groupId
      ) > -1;
    },
    toWords: converter.toWords,
    fetchPageData() {
      if (this.project && this.project.id && this.surveyId) {
        this.getTemBiasForWholeSurvey({
          projectId: this.project.id,
          surveyId: this.surveyId
        });
      }
    },
    onEnumeratorUpdate(enumeratorIndex, teamIndex, updatedEnumerator) {
      this.$set(this.teams[teamIndex], enumeratorIndex, updatedEnumerator);
      this.setEnumerators(Object.values(this.teams).flat());
      this.computeTeams();
    },
    computeTeams() {
      const augmentedEnumerators = this.enumerators.map(item => ({
        ...item,
        teamRole: item.teamRole == null ? undefined : item.teamRole,
        tembias: this.temBias.filter(temBiasItem => parseInt(temBiasItem.label, 0) === item.id)
      }));

      const enumSorter = (enumA, enumB) =>
        (enumA.rank !== null ? enumA.rank : 0) -
        (enumB.rank !== null ? enumB.rank : 0);

      const enumeratorsWithTeams = augmentedEnumerators
        .filter(enumerator => enumerator.teamId);

      const enumeratorsWithoutTeams = augmentedEnumerators.filter(enumerator => !enumerator.teamId);

      for (let i = 0; i < this.teamCount; i += 1) {
        this.$set(this.teams, i + 1, []);
      }
      if (enumeratorsWithTeams.length > 0) {
        const groupedByTeam = groupBy(enumeratorsWithTeams, 'teamId');

        for (const [key, _] of Object.entries(groupedByTeam)) {
          groupedByTeam[key].sort(enumSorter);
        }

        enumeratorsWithoutTeams.sort(enumSorter);

        this.teams = {
          ...this.teams,
          0: [...enumeratorsWithoutTeams],
          ...groupedByTeam
        };
      } else {
        this.$set(this.teams, 0, [...augmentedEnumerators]);
        this.startTour();
      }
      this.previousEnums = Object.fromEntries(Object.values(this.teams)
        .flat().map(enumerator => [enumerator.id, { ...enumerator }]));
    },
    selectRow(e, teamRow, enumeratorRow) {
      if (e.shiftKey) {
        this.$set(this.teams[teamRow][enumeratorRow], 'selected', !this.teams[teamRow][enumeratorRow].selected);
      }
    },
    onViewEnumerator(e, enumerator) {
      // if shift is active, return
      if (e && e.shiftKey) {
        return;
      }

      // if any of the tours are active, return;
      if ((this.$tours.firstStepTour && this.$tours.firstStepTour.isRunning) ||
      (this.$tours.secondStepTour && this.$tours.secondStepTour.isRunning)) return;
      // assign enumeratoor
      this.enumeratorViewing = enumerator || undefined;
    },
    toggleExpand($event) {
      $event
        .target
        .parentElement
        .parentElement
        .parentElement
        .classList
        .toggle('toggle-closed');
    },
    onDragStart() {
      this.$tours.firstStepTour.stop();
      this.$tours.secondStepTour.stop();
    },
    onDragEnd(event) {
      if (event.originalEvent.shiftKey) {
        return;
      }
      const toIndex = event.to.getAttribute('data-index');
      const fromIndex = event.from.getAttribute('data-index');

      const previousRank = event.oldDraggableIndex;
      const newRank = event.newDraggableIndex;
      const enumeratorRank = newRank;

      const enumeratorId = parseInt(event.item.getAttribute('data-enumerator'), 0);
      if (toIndex === fromIndex && previousRank === newRank) {
        return;
      }

      for (const team in this.teams) {
        if (Object.prototype.hasOwnProperty.call(this.teams, team)) {
          const selected = this.teams[team]
            .filter(t => t.selected)
            .map(enumerator => ({
              ...enumerator,
              teamId: parseInt(toIndex, 0),
              rank: enumeratorRank,
              teamRole: undefined,
              selected: false
            }));
          const remaining = this.teams[team]
            .filter(t => !t.selected)
            .map(enumerator => {
              if (enumerator.id === enumeratorId) {
                return {
                  ...enumerator,
                  teamId: parseInt(toIndex, 0),
                  rank: enumeratorRank,
                  teamRole: undefined,
                  selected: false
                };
              }
              return enumerator;
            });
          this.teams[team] = remaining;
          this.teams[toIndex] = [...selected, ...this.teams[toIndex]];

          if (this.teams[0].length === 0) {
            if (this.currentStep === 0) {
              this.currentStep = 1;
            }
          }
        }
      }
      this.updateValue();
    },
    addTeam() {
      const teamCount = Object.keys(this.teams).length;
      this.teams = {
        ...this.teams,
        [teamCount]: []
      };
    },
    deleteTeam(teamIndex) {
      this.$tours.firstStepTour.stop();
      this.$tours.secondStepTour.stop();
      const unassigned = [
        ...this.teams[0],
        ...this.teams[teamIndex].map(enumerator => ({ ...enumerator, teamId: 0, teamRole: undefined }))
      ];
      delete this.teams[teamIndex];
      const newTeams = {};
      Object
        .keys(this.teams)
        .forEach((key, index) => {
          newTeams[index] = this.teams[key];
        });
      this.teams = { ...newTeams, 0: unassigned };
      this.updateEnumeratorTeams();
    },
    teamHasRole(teamIndex, role) {
      return !!this.teams[teamIndex].find(enumerator => enumerator.teamRole === role);
    },
    async sendEnumeratorTeams() {
      if (this.previousEnums !== null) {
        const assignDefaultRank = (item, index) => {
          // eslint-disable-next-line no-param-reassign
          item.rank = index;
          return item;
        };

        for (const [team, _] of Object.entries(this.teams)) {
          this.teams[team] = this.teams[team].map(assignDefaultRank);
        }
        const newEnums = Object.values(this.teams).flat();

        const filteredEnums = newEnums
          .filter(enumerator =>
            enumerator.teamId !== this.previousEnums[enumerator.id].teamId ||
            enumerator.teamRole !== this.previousEnums[enumerator.id].teamRole ||
            enumerator.rank !== this.previousEnums[enumerator.id].rank);

        const updates = [];
        newEnums.forEach(enumerator => {
          const { id, teamRole, teamId, rank } = enumerator;
          const data = {
            enumeratorId: id,
            teamId,
            teamRole: teamRole || null,
            rank
          };
          updates.push(
            data
          );
        });

        if (filteredEnums.length > 0) {
          this.$message.loading({ content: this.$t('components.notifications.savingDot'), key: messageKey });
          await this.updateEnumeratorRanksForSurvey({ surveyId: this.surveyId, data: updates }).then((data) => {
            this.setEnumerators(data);
            this.$message.success({ content: this.$t('components.notifications.saved'), key: messageKey, duration: 1 });
          });
        }

        this.previousEnums = Object.fromEntries(newEnums.map(enumerator =>
          [enumerator.id, { ...enumerator }]));
      }
    },
    updateEnumeratorTeams: debounce(function() {
      this.sendEnumeratorTeams();
    }, debounceDelay),
    updateValue() {
      const values = Object.keys(this.teams)
        .filter(key => key !== '0')
        .map(key => {
          const enums = this.teams[key];
          return enums.map(enumerator => ({
            ...enumerator,
            teamId: parseInt(key, 0)
          }));
        }).flat();
      this.$emit('input', values);
      this.updateEnumeratorTeams();

      // Set current step to 3 if all enumerators have a team role
      const allTeamRoles = values.map(value => value.teamRole).filter(value => value);
      if (allTeamRoles.length > 0 && allTeamRoles.length === values.length) {
        this.currentStep = 2;
      }
    },
    async onUpdate(enumerator, values) {
      const updatedEnumerator = await this.updateEnumeratorInGroup(
        {
          enumeratorId: enumerator.id,
          groupId: enumerator.groupId,
          data: values
        }
      );
      return updatedEnumerator;
    },
    startTour() {
      const isSkipped = window.localStorage.getItem('skipEnumeratorTour');
      if (isSkipped) return;
      this.$tours.firstStepTour.start();
    }
  }
};
</script>

<style lang="scss">
  .enumerator-teams {
    user-select: none;

    .drag-handle {
      cursor: grab;
    }

    .ant-select-selection {
      height: auto !important;
    }

    .team-card {
      margin-bottom: 20px;
    }

    .team-container {
      embed {
        transition: transform 0.2s ease-in;
      }
    }

    .team-container.toggle-closed {
      embed {
        transform: rotate(180deg);
      }

      > div {
        display: none;
        &:first-child {
          display: block;
        }
      }
    }

    .team-container > div:first-child {
      min-height: 50px;
      display: flex;
      align-items: center;
    }

    .team-row-empty {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }

    .enumerator-row ~ .team-row-empty {
      display: none;
    }

    .team-row {
      background: white;
      display: grid;
      grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0 10px;
      grid-template-areas:
        ". . . . . . .";
      align-items: center;
      min-height: 50px;
      &.header {
        background: #f4f4f5;
        border-bottom: solid 0.5px rgba(142, 144, 143, 0.253);
        border-top: solid 0.5px rgba(142, 144, 143, 0.253);
      }
    }

    .enumerator-row {
      border-bottom: solid 0.5px rgba(142, 144, 143, 0.253);
      &:last-child {
        border-bottom: none;
      }
      &.ghost {
        border: 2px dashed #0d7aff81;
        opacity: 0.5;
      }
      &.selected {
        background: #eaf3fc;
      }
    }

    .enumerator-dropzone {
      min-height: 50px;
      background: white;
    }

    .border-top {
      border-top: 1px solid rgba(142, 144, 143, 0.253);
    }
  }
</style>
